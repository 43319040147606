import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import SiteLinks from './components/site-links'
import PeopleComponent from './components/people'
import BTS from './components/bts'
import PocketLog from './components/pocket-log'

function People({ data: { people } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <SiteLinks activePage="pocketeers" />
      <PeopleComponent people={people} />
      <BTS />
      <PocketLog />
    </Layout>
  )
}

People.propTypes = {
  data: PropTypes.object.isRequired,
}

export default People
