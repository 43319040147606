import React from 'react'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'

function BTS() {
  return (
    <Section>
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-6 has-text-centered">
            <p className="">
              <i>Psssssst!</i> See what we've been up to{' '}
              <Link to="/blog/categories/get-to-know-pocketworks">
                behind the scenes.
              </Link>
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default BTS
