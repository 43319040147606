import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

function PocketLog() {
  return (
    <Section className="is-hidden">
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-10">
            <h2 className="has-text-centered">
              Pocket<span className="has-text-success">Log</span>
            </h2>

            <div className="box pocketlog">
              <Columns className="is-vcentered">
                <Columns.Column className="is-narrow is-1">
                  <img
                    src="https://www.datocms-assets.com/20955/1580426840-adam.jpeg?auto=compress%2Cformat&dpr=0.75&fm=jpg&w=100&mask=ellipse"
                    alt="Adam"
                    className="level-item"
                  />
                </Columns.Column>
                <Columns.Column>
                  <p>
                    <span className="is-size-6 has-text-grey">
                      Adam, Feb 11th, 2020
                    </span>
                    <br />
                    Released iOS app update to 50,000 customers to help a taxi
                    firm give a better driver experience.
                  </p>
                  <span className="tag is-warning is-light is-uppercase">
                    App Development
                  </span>
                </Columns.Column>
              </Columns>
            </div>

            <div className="box pocketlog">
              <Columns className="is-vcentered">
                <Columns.Column className="is-narrow is-1">
                  <img
                    src="https://www.datocms-assets.com/20955/1580426968-rory.jpeg?auto=compress%2Cformat&dpr=0.75&fm=jpg&w=100&mask=ellipse"
                    alt="Rory"
                    className="level-item"
                  />
                </Columns.Column>
                <Columns.Column>
                  <p>
                    <span className="is-size-6 has-text-grey">
                      Rory, Feb 9th, 2020
                    </span>
                    <br />
                    Put together a risk-mitigation roadmap to help our customers
                    prepare for 2020 iOS changes.
                  </p>
                  <span className="tag is-warning is-light is-uppercase">
                    Planning
                  </span>
                </Columns.Column>
              </Columns>
            </div>

            <div className="box pocketlog">
              <Columns className="is-vcentered">
                <Columns.Column className="is-narrow is-1">
                  <img
                    src="https://www.datocms-assets.com/20955/1580427163-matteo.jpg?auto=compress%2Cformat&dpr=0.75&fm=jpg&w=100&mask=ellipse"
                    alt="Matteo"
                    className="level-item"
                  />
                </Columns.Column>
                <Columns.Column>
                  <p>
                    <span className="is-size-6 has-text-grey">
                      Matteo, Feb 9th, 2020
                    </span>
                    <br />
                    Launched the first version of a mobile working app for a
                    construction startup.
                  </p>
                  <span className="tag is-warning is-light is-uppercase">
                    Launch!
                  </span>
                </Columns.Column>
              </Columns>
            </div>

            <div className="box pocketlog">
              <Columns className="is-vcentered">
                <Columns.Column className="is-narrow is-1">
                  <img
                    src="https://www.datocms-assets.com/20955/1579120437-anna.jpg?auto=compress%2Cformat&dpr=0.75&fm=jpg&w=100&mask=ellipse"
                    alt="Anna"
                    className="level-item"
                  />
                </Columns.Column>
                <Columns.Column>
                  <p>
                    <span className="is-size-6 has-text-grey">
                      Anna, Jan 13th, 2020
                    </span>
                    <br />
                    Interviewed 27 ski holidaymakers to help a travel startup
                    validate their product-market fit.
                  </p>
                  <span className="tag is-warning is-light is-uppercase">
                    User Research
                  </span>
                </Columns.Column>
              </Columns>
            </div>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default PocketLog
