/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import People from 'routes/about/pocketeers'

// Meta Component
import Meta from 'components/Meta/Meta'

// People Route
export default People

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}
export const query = graphql`
  query PeopleQuery {
    page: datoCmsPage(slug: { eq: "pocketeers" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    people: allDatoCmsAuthor(
      limit: 30
      sort: { fields: [position], order: ASC }
      filter: { isActive: { eq: true } }
    ) {
      edges {
        node {
          id
          name
          slug
          role
          firstName
          isActive
          mainImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "contain"
                sat: -5
                invert: false
              }
            )
          }
        }
      }
    }
  }
`
